import React, { useState } from "react";
import Home from './Components/Home';
import Results from "./Components/Search/Results";
import InvalidResults from "./Components/Search/InvalidResults";
import NotFound from "./Components/NotFound/404";
import Listings from "./Components/ListingInformation/Listings";
import MyGarage from "./Components/MyGarage/MyGarage";
import Navbar from "./Components/Navbar/Navbar";
import Footer from "./Components/Footer/Footer";
import AboutUs from "./Components/AboutUs/AboutUs";
import PrivacyPolicy from "./Components/PrivacyPolicy/PrivacyPolicy";
import ConactUs from './Components/ContactUs/ContactUs';
import Dashboard from './Components/Dashboard/Dashboard';
import Refresh from './Components/Accounts/Refresh';
import GlobalStyle from "./Styles/Global";
import {Route, Switch} from "react-router-dom";

export default function App() {

    const [navbarOpen, setNavbarOpen] = useState(false);

    const handleNavbar = () => {
        setNavbarOpen(!navbarOpen);
    }


    return (
        <>
            <Switch>
                <Route exact path={"/"} component={Home}/>
                <Route exact path={"/mygarage"} component={MyGarage}/>
                <Route exact path={"/results"} component={Results}/>
                <Route exact path={"/listings/:id"} component={Listings}/>
                <Route exact path={"/about"} component={AboutUs}/>
                <Route exact path={"/privacy"} component={PrivacyPolicy}/>
                <Route exact path={"/contact"} component={ConactUs}/>
                <Route exact path={"/dashboard"} component={Dashboard}/>
                <Route exact path={"/contact"} component={ConactUs}/>
                <Route exact path={"/refresh"} component={Refresh}/>
                <Route exact path={"/invalid-results"} component={InvalidResults}/>
                <Route component={NotFound} />
            </Switch>


            <Navbar
                navbarState={navbarOpen}
                handleNavbar={handleNavbar}
            />

            <Footer/>

            <GlobalStyle/>
        </>
    );


}
