import React, {useEffect} from "react";
import styled from "styled-components";
import {Container} from "react-bootstrap";
import {useAuth0} from "@auth0/auth0-react";
import UnderConstruction from "../../Assets/Images/UnderContruction.jpg";
import Profile from "./Profile";
import NotSignedIn from "./NotSignedIn";

const Styled = styled.div`
    .mainDiv {
          text-align: center;
          width: 100%;
          margin: 0 auto;
          padding-bottom: 55px;
          padding-top: 25px;
    }
    
    .contructionImage {
        padding-top: 24px;
        width: 100%;
    }
 
`

export default function MyGarage() {
    const {user, isAuthenticated} = useAuth0();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    if (isAuthenticated) return (
        <>
            <Styled>
                <Profile/>
            </Styled>
        </>
    );
    return (
        <>
            <Styled>
                <NotSignedIn/>
            </Styled>
        </>
    )
}