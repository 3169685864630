import React from 'react';
import {Col, Row} from "react-bootstrap";

const DashboardCard = (props) => {
    return (
        <Col xl="3" md="4" className={'mb-3'} style={{'color': props.colour}}>
            <div className={'card card-colour-line'}>
                <div className="card-body">
                    <Row style={{'align-items': 'center', 'margin': '0 0',}}>
                        <Col className={'mr-2'}>
                            <div className={"card-title text-uppercase mb-1"}>{props.title}</div>
                            <div className={"card-text"}>{props.info}</div>
                        </Col>
                    </Row>
                </div>
            </div>
        </Col>
    );
}

export default DashboardCard;