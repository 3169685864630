import React from 'react'
import styled from "styled-components";
import {animated, config, useSpring} from "react-spring";
import AccountManager from "../Accounts/AccountManager";
import Brand from "./Brand";
import BurgerMenu from "./BurgerMenu";
import CollapseMenu from "./CollapseMenu";
import Nav from "react-bootstrap/Nav";

const Navbar = (props) => {
    const barAnimation = useSpring({
        from: {transform: 'translate3d(0, -10rem, 0)'},
        transform: 'translate3d(0, 0, 0)',
    });

    const linkAnimation = useSpring({
        from: {transform: 'translate3d(0, 30px, 0)', opacity: 0},
        to: {transform: 'translate3d(0, 0, 0)', opacity: 1},
        delay: 800,
        config: config.wobbly,
    });

    return (
        <>
            <NavBar style={barAnimation}>
                <FlexContainer>
                    <Brand/>
                    <Nav>
                        <NavLinks style={linkAnimation}>
                            <a href="/">Home</a>
                            <a href="/mygarage">My Garage</a>
                            <a href="/contact">Contact</a>
                            <AccountManager/>
                        </NavLinks>
                    </Nav>
                    <BurgerWrapper>
                        <BurgerMenu
                            navbarState={props.navbarState}
                            handleNavbar={props.handleNavbar}
                        />
                    </BurgerWrapper>
                </FlexContainer>
            </NavBar>
            <CollapseMenu
                navbarState={props.navbarState}
                handleNavbar={props.handleNavbar}
            />
        </>
    )
}

export default Navbar

const NavBar = styled(animated.nav)`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background: #004a75;
  z-index: 25;
  font-size: 1.1rem;
`;

const FlexContainer = styled.div`
  max-width: 120rem;
  display: flex;
  margin: auto;
  padding: 0 2rem;;
  justify-content: space-between;
  height: 5rem;
`;

const NavLinks = styled(animated.ul)`
  display:inline;
  justify-self: end;
  list-style-type: none;
  margin: auto 0;

  & a {
    color: #dfe6e9;
    text-transform: uppercase;
    font-weight: 600;
    border-bottom: 1px solid transparent;
    margin: 0 1.5rem;
    transition: all 300ms linear 0s;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: #fdcb6e;
      border-bottom: 1px solid #fdcb6e;
    }

    @media (max-width: 839px) {
      display: none;
    }
  }
`;

const BurgerWrapper = styled.div`
  margin: auto 0;

  @media (min-width: 840px) {
    display: none;
  }
`;