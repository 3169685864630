import React, {useState} from "react";
import {Button, Col, Image, ListGroup, Row} from "react-bootstrap";
import styled from "styled-components";
import EBAY from '../../Assets/Images/EBAY.svg.png';
import CARANDCLASSIC from '../../Assets/Images/CARANDCLASSIC.svg.png';
import PISTONHEADS from '../../Assets/Images/PISTONHEADS.svg.png';
import AUCTION from '../../Assets/Images/auction-icon.png';
import {useHistory} from "react-router-dom";
import Overview from "./Overview";

const Styled = styled.div`
    .thumbnail {
        width: 100%;
    }
    
    .source {
        position: absolute;
        right: 10px;
        bottom: 55px;
        max-height: 50px; 
        max-width: 145px;  
        @media (max-width: 670px) {
                display: block;
                margin-left: auto;
            }
    }
    
    .title {
        color: #004a75;
        @media (max-width: 670px) {
                padding-top: 20px;
                text-align: center;
            }
    }
    
    .price {
        color: #004a75;
    }
    
    .overview {
        color: #474747;
        @media (max-width: 670px) {
                padding-left: 25px;
            }
    }
    
    .moreInfo {
        max-height: 38px;
        min-width: 167px;
        position: absolute;
        bottom: 10px;
        right: 10px;
        @media (max-width: 670px) {
              display: block;
              margin-left: auto;
              margin-right: auto;
            }
    }
    
    .resultCard {
        padding-top: 5px;
        padding-bottom: 5px;
    }
    
    .sourceLogo {
        padding: 10px 5px;
        margin-left: 5%;
    }
    
    .loadingBox {
        height: 200px;
        width: 100%;
        background-color: #e9ecef;
    }
    
    .auctionIcon {
        height: 25px;
        width: 25px;
    }
    
    .auctionChecked {
        font-size: 0.75em;
    }
`

export default function BigCard(props) {
    const [imgLoading, setImgLoading] = useState(true);

    const history = useHistory();

    const handleImageLoaded = () => {
        setImgLoading(false)
    }

    const handleClick = () => {
        history.push({pathname: `/listings/${props.info._id}`, state: {prevPath: history.location.search}})
    }

    const renderSource = (source) => {
        switch(source) {
            case 'ebay':
                return <Image className={'source'} src={EBAY} fluid/>;
            case 'piston_heads':
                return <Image className={'source'} src={PISTONHEADS} fluid/>;
            case 'car_and_classic':
                return <Image className={'source'} src={CARANDCLASSIC} fluid/>;
            default:
                return <h4>Error: Unknown source</h4>;
        }
    }

    return (
        <>
            <Styled>
                <ListGroup className={'resultCard'}>
                    <ListGroup.Item>
                        <Row>
                            <Col sm={4}>
                                <div style={{display: imgLoading ? "none" : "block"}}>
                                {props.info.pictures?
                                    props.info.pictures.picture_1?
                                        <Image className={'thumbnail'} onLoad={handleImageLoaded} src={props.info.pictures.picture_1} fluid/>
                                        :
                                        <div className={'loadingBox'}/>
                                    :
                                    <div className={'loadingBox'}/>
                                }
                                </div>
                                <div style={{"display": imgLoading ? "block" : "none"}}>
                                    <div className={'loadingBox'}/>
                                </div>

                            </Col>
                            <Col style={{"display": "flex", "flex-direction": "column"}}>
                                <Row>
                                    <Col className={'title'} sm={8}>
                                        <h3>{props.info.title.headline_title}</h3>
                                    </Col>
                                    <Col className={'price'} sm={4}>
                                        <h3>£{props.info.price.amount > 0 ? props.info.price.amount : "POA"}</h3>
                                        {
                                            props.info.listingType === 'auction'?
                                                <div>
                                                    <h4><Image className={'auctionIcon'} src={AUCTION} alt={"Auction"} fluid/>  AUCTION</h4>
                                                    <p className={"auctionChecked"}>Last updated: {props.info.lastChecked}</p>
                                                </div>
                                                :
                                                null
                                        }
                                    </Col>
                                </Row>
                                <hr/>
                                <Row style={{"flex-grow": "1"}}>
                                    <Col className={'overview'} sm={8}>
                                        <Overview info={props.info}/>
                                    </Col>
                                    <Col className={'sourceLogo'}>
                                        <div style={{'height': '100%'}}>
                                            {
                                                renderSource(props.info.source)
                                            }
                                            <Button className={'moreInfo'} variant="success" onClick={handleClick}>More Information</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </ListGroup.Item>
                </ListGroup>
            </Styled>
        </>
    );
}