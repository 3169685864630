import React from 'react';
import {Col, Row} from "react-bootstrap";

const UnclassifiedCard = (props) => {
    return (
        <Col xl="3" md="4" className={'mb-3'} style={{'color': props.colour}}>
            <div className={'card card-colour-line'}>
                <div className="card-body">
                    <Row style={{'align-items': 'center', 'margin': '0 0',}}>
                        <Col className={'mr-2'}>
                            <div className={"unclassified-title text-uppercase mb-1"}>Unclassified</div>
                            <div className={"unclassified-small-title mb-1"}>Models</div>
                            <div className={"unclassified-text"}>{props.info["total_unclassified_models" + props.source]}</div>
                            <div className={"unclassified-small-title mb-1"}>Model Variants</div>
                            <div className={"unclassified-text"}>{props.info["total_unclassified_model_variants" + props.source]}</div>
                            <div className={"unclassified-small-title mb-1"}>Mileage</div>
                            <div className={"unclassified-text"}>{props.info["total_no_mileage" + props.source]}</div>
                            <div className={"unclassified-small-title mb-1"}>Colour</div>
                            <div className={"unclassified-text"}>{props.info["total_no_colour" + props.source]}</div>
                            <div className={"unclassified-small-title mb-1"}>Price</div>
                            <div className={"unclassified-text"}>{props.info["total_no_price" + props.source]}</div>
                            <div className={"unclassified-small-title mb-1"}>Gearbox</div>
                            <div className={"unclassified-text"}>{props.info["total_no_gearbox" + props.source]}</div>
                            <div className={"unclassified-small-title mb-1"}>Year</div>
                            <div className={"unclassified-text"}>{props.info["total_no_year" + props.source]}</div>
                        </Col>
                    </Row>
                </div>
            </div>
        </Col>
    );
}

export default UnclassifiedCard;
