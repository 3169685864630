import React, {useState} from "react";
import {useHistory} from 'react-router-dom';
import SourcesSlider from "../Explainers/SourcesSlider";
import styled from 'styled-components';
import mainBackground from '../../Assets/Images/mainBackground.png';
import PorscheFinderLogo from "../../Assets/Images/PorscheFinderLogo.png";
import {Button, Col, Container, Form, Image, Jumbotron, Row} from 'react-bootstrap';
import ModelVariant from "./ModelVariant";
import Model from "./Model";

const Styled = styled.div`
    .homeJumbo {
        background: url(${mainBackground});
        background-size: cover;
        position: relative;
        height: 1000px;
        padding-top: 200px;
        padding-bottom: 100px;
        
        @media (max-width: 841px) {
            padding-top: 170px;
        }
    }
    
    .PorscheFinderLogo {
          display: block;
          margin-left: auto;
          margin-right: auto;
          width: 850px;
          
            @media (max-width: 841px) {
                width: 100%;
            }

    }
    
    .form-control {
        
    }
    
    .form-row {
        padding-bottom: 15px;
    }
    
    .searchForm {
        padding-top: 10px;
        max-width: 900px;
        margin: auto;
        font-size: 100%;
    }
    
    .searchButton {
        width: 100%
    }
    
    .landingText {
        text-align: center;
        color: white;
        font-size: large;
    }
    
    
`


export default function Search(props) {

    const [Filters, setFilters] = useState([{
        model: '',
        modelVariant: '',
        minPrice: '',
        maxPrice: '',
        minYear: '',
        maxYear: '',
        keyword: '',
    }]);

    let history = useHistory();

    const SendSearch = () => {
        let queryParams = ""
        if (Filters.model !== undefined) {
            queryParams = queryParams + `model=${Filters.model}`
        } else {
            queryParams = queryParams + `model=`
        }
        if (Filters.modelVariant !== undefined) {
            queryParams = queryParams + `&modelVariant=${Filters.modelVariant}`
        } else {
            queryParams = queryParams + `&modelVariant=`
        }
        if (Filters.minPrice !== undefined) {
            queryParams = queryParams + `&minPrice=${Filters.minPrice}`
        } else {
            queryParams = queryParams + `&minPrice=`
        }
        if (Filters.maxPrice !== undefined) {
            queryParams = queryParams + `&maxPrice=${Filters.maxPrice}`
        } else {
            queryParams = queryParams + `&maxPrice=`
        }
        if (Filters.minYear !== undefined) {
            queryParams = queryParams + `&minYear=${Filters.minYear}`
        } else {
            queryParams = queryParams + `&minYear=`
        }
        if (Filters.maxYear !== undefined) {
            queryParams = queryParams + `&maxYear=${Filters.maxYear}`
        } else {
            queryParams = queryParams + `&maxYear=`
        }
        if (Filters.keyword !== undefined) {
            queryParams = queryParams + `&keyword=${Filters.keyword}`
        } else {
            queryParams = queryParams + `&keyword=`
        }

        history.push({
            pathname: '/results',
            search: queryParams,
            state: {Filters}
        })

    }

    const HandleModelChange = (event) => {
        event.preventDefault();
        setFilters({
            ...Filters,
            model: event.target.value
        });
    }

    const HandleModelVariantChange = (event) => {
        event.preventDefault();
        setFilters({
            ...Filters,
            modelVariant: event.target.value
        });
    }

    const HandleMinPriceChange = (event) => {
        event.preventDefault();
        setFilters({
            ...Filters,
            minPrice: event.target.value
        });
    }

    const HandleMaxPriceChange = (event) => {
        event.preventDefault();
        setFilters({
            ...Filters,
            maxPrice: event.target.value
        });
    }

    const HandleMinYearChange = (event) => {
        event.preventDefault();
        setFilters({
            ...Filters,
            minYear: event.target.value
        });
    }

    const HandleMaxYearChange = (event) => {
        event.preventDefault();
        setFilters({
            ...Filters,
            maxYear: event.target.value
        });
    }

    const HandleKeywordChange = (event) => {
        event.preventDefault();
        setFilters({
            ...Filters,
            keyword: event.target.value
        });
    }

    let maxOffset = 70;
    let thisYear = (new Date()).getFullYear();
    let allYears = [];
    for (let x = 0; x <= maxOffset; x++) {
        allYears.push(thisYear - x)
    }

    const yearList = allYears.map((x) => {
        return (<option key={x}>{x}</option>)
    });
    const yearListReverse = yearList.slice().reverse()


    return (
        <>
            <Styled>
                <Jumbotron className={'homeJumbo'}>
                    <Container>
                        <Image className={'PorscheFinderLogo'} src={PorscheFinderLogo}/>
                    </Container>

                    <Form className={'searchForm'}>
                        <Form.Group>
                            {/* START: ROW 1 */}
                            <Row className={'form-row'}>
                                <Col>
                                    <Model model={Filters.model} modelVariant={Filters.modelVariant}
                                                  Filters={Filters}
                                                  HandleModelChange={HandleModelChange}/>
                                </Col>
                                <Col>
                                    <ModelVariant model={Filters.model} modelVariant={Filters.modelVariant}
                                                  Filters={Filters}
                                                  HandleModelVariantChange={HandleModelVariantChange}/>
                                </Col>
                            </Row>
                            {/* END: ROW 1 */}
                        </Form.Group>

                        <Form.Group>
                            {/* START: ROW 2 */}
                            <Row className={'form-row'}>
                                <Col>
                                    <select className="form-control" name="minPrice" onChange={HandleMinPriceChange}>
                                        <option value="undefined">Min price</option>
                                        <option value="1000">£1,000</option>
                                        <option value="2500">£2,500</option>
                                        <option value="5000">£5,000</option>
                                        <option value="10000">£10,000</option>
                                        <option value="15000">£15,000</option>
                                        <option value="20000">£20,000</option>
                                        <option value="25000">£25,000</option>
                                        <option value="30000">£30,000</option>
                                        <option value="35000">£35,000</option>
                                        <option value="40000">£40,000</option>
                                        <option value="45000">£45,000</option>
                                        <option value="50000">£50,000</option>
                                        <option value="55000">£55,000</option>
                                        <option value="60000">£60,000</option>
                                        <option value="65000">£65,000</option>
                                        <option value="70000">£70,000</option>
                                        <option value="80000">£80,000</option>
                                        <option value="90000">£90,000</option>
                                        <option value="100000">£100,000</option>
                                        <option value="120000">£120,000</option>
                                        <option value="140000">£140,000</option>
                                        <option value="160000">£160,000</option>
                                        <option value="180000">£180,000</option>
                                        <option value="200000">£200,000</option>
                                    </select>
                                </Col>
                                <Col>
                                    <select className="form-control" name="maxPrice" onChange={HandleMaxPriceChange}>
                                        <option value="undefined">Max price</option>
                                        <option value="1000">£1,000</option>
                                        <option value="2500">£2,500</option>
                                        <option value="5000">£5,000</option>
                                        <option value="10000">£10,000</option>
                                        <option value="15000">£15,000</option>
                                        <option value="20000">£20,000</option>
                                        <option value="25000">£25,000</option>
                                        <option value="30000">£30,000</option>
                                        <option value="35000">£35,000</option>
                                        <option value="40000">£40,000</option>
                                        <option value="45000">£45,000</option>
                                        <option value="50000">£50,000</option>
                                        <option value="55000">£55,000</option>
                                        <option value="60000">£60,000</option>
                                        <option value="65000">£65,000</option>
                                        <option value="70000">£70,000</option>
                                        <option value="80000">£80,000</option>
                                        <option value="90000">£90,000</option>
                                        <option value="100000">£100,000</option>
                                        <option value="120000">£120,000</option>
                                        <option value="140000">£140,000</option>
                                        <option value="160000">£160,000</option>
                                        <option value="180000">£180,000</option>
                                        <option value="200000">£200,000+</option>
                                    </select>
                                </Col>
                            </Row>
                            {/* END: ROW 2 */}
                        </Form.Group>

                        <Form.Group>
                            {/* START: ROW 3 */}
                            <Row className={'form-row'}>
                                <Col>
                                    <select className="form-control" name="minYear" onChange={HandleMinYearChange}>
                                        <option value="undefined">Min year</option>
                                        {yearListReverse}
                                    </select>
                                </Col>
                                <Col>
                                    <select className="form-control" name="maxYear" onChange={HandleMaxYearChange}>
                                        <option value="undefined">Max year</option>
                                        {yearList}
                                    </select>
                                </Col>
                            </Row>
                            {/* END: ROW 3 */}
                        </Form.Group>

                        <Form.Group>
                            {/* START: ROW 4 */}
                            <Row className={'form-row'}>
                                <Col>
                                    <input className="form-control" type="text"
                                           placeholder="Keyword..." name="keyword" onChange={HandleKeywordChange}>
                                    </input>
                                </Col>
                                <Col>
                                    <Button
                                        className={'searchButton'}
                                        variant={'success'}
                                        onClick={SendSearch}
                                    >
                                        Search
                                    </Button>
                                </Col>
                            </Row>
                            {/* END: ROW 4 */}
                        </Form.Group>
                    </Form>
                </Jumbotron>
            </Styled>
            <SourcesSlider/>
        </>
    )

}

