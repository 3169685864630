import React, {useState} from "react";
import styled from "styled-components";
import {useAuth0} from "@auth0/auth0-react";
import {useApi} from "../use-api";
import {Spinner, Button, Row} from "react-bootstrap"
import PerformDelete from "./PerformDelete";


const Styled = styled.div`
    .tableWrapper {
        width: 100%;
    }

    .table {
        width: 95%;
        display: table;
        margin: 0;
        margin-left: auto;
        margin-right: auto;
    }
    @media screen and (max-width: 768px) {
        .table {
            display: block;
            width: 100%;
        }
    }
    
    .row {
        display: table-row;
        background: #fff;
        width: 100% !important;
    }
    
    .row.header {
        color: #fff;
        background: #004a75;
    }
    @media screen and (max-width: 768px) {
        .row {
            display: block;
        }
        
        .row.header {
            padding: 0;
            height: 0;
        }
        
        .row.header .cell {
            display: none;
        }
        .row .cell:before {
            font-size: 12px;
            color: gray;
            line-height: 1.2;
            text-transform: uppercase;
            font-weight: unset !important;
            margin-bottom: 13px;
            content: attr(data-title);
            min-width: 98px;
            display: block;
        }
    }

    .cell {
        display: table-cell;
    }
    @media screen and (max-width: 768px) {
        .cell {
            display: block;
        }
    }

    .row .cell {
        font-size: 15px;
        color: #666;
        line-height: 1.2;
        font-weight: unset !important;
        padding-top: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid #f2f2f2;
    }
    
    .row.header .cell {
        font-size: 18px;
        color: #fff;
        line-height: 1.2;
        font-weight: unset !important;
        padding-top: 19px;
        padding-bottom: 19px;
    }

    .row .cell:nth-child(1) {
        width: 30px;
        padding-left: 40px;
    }
    
    .row .cell:nth-child(2) {
        width: 250px;
    }
    
    .row .cell:nth-child(3) {
        width: 200px;
    }
    
    .row .cell:nth-child(4) {
        width: 200px;
    }
    
    .row .cell:nth-child(5) {
        width: 200px;
    }
    
    .row .cell:nth-child(6) {
        width: 200px;
    }
    
    .row .cell:nth-child(7) {
        width: 200px;
    }
    
    .row .cell:nth-child(8) {
        width: 190px;
    }
    
    .row .cell:nth-child(9) {
        width: 150px;
    }
    
    @media (max-width: 768px) {
        .row {
            border-bottom: 1px solid #f2f2f2;
            padding-bottom: 18px;
            padding-top: 30px;
            padding-right: 15px;
            margin: 0;
        }
        
        .row .cell {
            width: 50% !important;
            display: inline-block;
            border: none;
            padding-left: 30px;
            padding-top: 0px;
            padding-bottom: 16px;
            font-size: 18px;
            color: #555;
            line-height: 1.2;
            font-weight: unset !important;
        }
        
        .row .cell:nth-child(1) {
            padding-left: 30px;
        }
        
        .table,
        .row {
            width: 100% !important;
        }
    } 
    
    .deleteButton {
        background-color: #bd2513;
        border-color: #bd2513;
    }

    .deleteButton:focus {
        box-shadow: 0 0 0 .2rem rgba(189,37,19,.5) !important;
    }
`



export default function Notifications() {
    const {user, isAuthenticated, isLoading} = useAuth0();
    const [deleteResult, setDeleteResult] = useState();
    const [deletingID, setDeletingID] = useState(false);
    let sendRequest = false;
    let deleteURL = ";"
    let getURL = "";
    let options = {};

    const [getResponse, setGetResponse] = useState(useApi(deleteURL, options))

    if (!isLoading) {
        getURL = 'https://5xxxkq4zoa.execute-api.eu-west-2.amazonaws.com/dev/notifications/getAll?userId=' + user.sub.replace("|", "_")
        deleteURL = 'https://5xxxkq4zoa.execute-api.eu-west-2.amazonaws.com/dev/notifications/delete?userId=' + user.sub.replace("|", "_") + "&notificationId="
        options = {
            audience: 'https://porscheheaven.eu.auth0.com/api/v2/'
        };
    }

    let {loading, error, refresh, data} = useApi(getURL, options)
    let dataList = []

    if (data !== null) {
        if (typeof data.message.forEach === 'function') {
            data.message.forEach((item, index) => {
                let row = [item.notificationId, item.query.model, item.query.modelVariant, item.query.year.max, item.query.year.min, item.query.price.max, item.query.price.min, item.query.keyword]
                dataList.push(row)
            })
        }
    }


    if (loading || isLoading) {
        return (
            <>
                <h3>Your Search Notifications</h3>
                <div style={{height: 100}}/>
                <Spinner animation="border" variant="primary" />
            </>
        )
    }

    if (error) {
        return (
            <>
                <h3>Your Search Notifications</h3>
                <div style={{height: 100}}/>
                <div>{error.toString()}</div>
            </>
        )
    }

    const HandleNotificationDelete = (instanseId) => {
        deleteURL = deleteURL + instanseId;
        setDeletingID(instanseId);
        setDeleteResult(<PerformDelete url={deleteURL} options={options} setDeletingID={setDeletingID} setDeleteResult={setDeleteResult}/>)
    }

    if (deletingID === "refresh") {
        window.location.reload(false);
    }
    else {
        return (
            <>
                <h3>Your Search Notifications</h3>

                {dataList.length > 0 && dataList
                    ?
                    <Styled>
                        <div className={"tableWrapper"}>
                            <div className={"table"}>
                                <Row className={"header"}>
                                    <div className={"cell"}>ID</div>
                                    <div className={"cell"}>Model</div>
                                    <div className={"cell"}>Model Variant</div>
                                    <div className={"cell"}>Max Year</div>
                                    <div className={"cell"}>Min Year</div>
                                    <div className={"cell"}>Max Price</div>
                                    <div className={"cell"}>Min Price</div>
                                    <div className={"cell"}>Keyword</div>
                                    <div className={"cell"}>Action</div>
                                </Row>
                                {dataList.map((item, index) => {
                                    return (
                                        <Row>
                                            <div className={"cell"} data-title={"ID"}>{index + 1}</div>
                                            <div className={"cell"} data-title={"Model"}>{item[1]}</div>
                                            <div className={"cell"} data-title={"Model Variant"}>{item[2]}</div>
                                            <div className={"cell"} data-title={"Max Year"}>{item[3]}</div>
                                            <div className={"cell"} data-title={"Min Year"}>{item[4]}</div>
                                            <div className={"cell"} data-title={"Max Price"}>{item[5]}</div>
                                            <div className={"cell"} data-title={"Min Price"}>{item[6]}</div>
                                            <div className={"cell"} data-title={"Keyword"}>{item[7]}</div>
                                            {deletingID === item[0] ?
                                                <div className={"cell"} data-title={""}>
                                                    {deleteResult}
                                                </div>
                                                :
                                                <div className={"cell"} data-title={""}>
                                                    <Button className={"deleteButton"} disabled={deletingID ? true : false } value={item[0]}
                                                            onClick={() => HandleNotificationDelete(item[0])}>Delete</Button>
                                                </div>
                                            }
                                        </Row>
                                    )
                                })}
                            </div>
                        </div>
                    </Styled>
                    :
                    <>
                        <p>No notifications found</p>
                    </>
                }

            </>
        )
    }

}