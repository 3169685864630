import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {useHistory} from "react-router-dom"
import {Accordion, Button, Card, Container, Form} from 'react-bootstrap';
import ModelVariant from "./ModelVariant";
import Model from "./Model";


const Styled = styled.div`
    
    .accordion {
        margin-bottom: 10px;
    }
    
    .accordionTitle {
        background-color: #FFFFFF;
        border: none;
        padding: 0 0;
    }
    
    .titleCard {
        border: none;
    }
    
    .filterForm {
        color: #004a75;
    }
    
    .filterCard {
        padding-bottom: 20px;
        border: none;
    }
    
    .filtersTitle {
        padding-top: 20px;
        text-align: center;
    }
    
    .filtersSubText {
        text-align: center;
    }
    
    .searchButton {
        margin: auto auto 5px;
        width: 100%;
    }
    
    .titleBody {
        padding: 0 0;
    } 
`

const SideBarFilters = (props) => {
    const [filters, setFilters] = useState({
        model: '',
        modelVariant: '',
        minPrice: '',
        maxPrice: '',
        minYear: '',
        maxYear: '',
        keyword: '',
        noPOA: false,
        count: 0,
    });

    const prevFilters = usePrevious(filters)
    useEffect(() => {
        if (prevFilters !== filters || filters.count === 1) {
            if(filters.count <= 1) {
                setFilters({
                    ...filters,
                    count: ++filters.count
                });
            }
            let info = props.info
            if (filters.count === 2) {
                setFilters({
                    ...filters,
                    model: info.model,
                    modelVariant: info.modelVariant,
                    minPrice: info.minPrice,
                    maxPrice: info.maxPrice,
                    minYear: info.minYear,
                    maxYear: info.maxYear,
                    keyword: info.keyword,
                    count: 3,
                    loading: true,
                });
            }
            if (info.minPrice === "1") {
                setFilters({
                    ...filters,
                    noPOA: true
                });
            }
            if (filters.minPrice === '' && filters.noPOA) {
                setFilters({
                    ...filters,
                    minPrice: "1"
                });
            }
            if (filters.minPrice === '1' && !filters.noPOA) {
                setFilters({
                    ...filters,
                    minPrice: ""
                });
            }
        }
    }, [filters.count, filters.noPOA]);

    let history = useHistory();

    const handleSubmit = () => {
        let queryParams = ""
        if (filters.model !== undefined) {
            queryParams = queryParams + `model=${filters.model}`
        } else {
            queryParams = queryParams + `&model=`
        }
        if (filters.modelVariant !== undefined) {
            queryParams = queryParams + `&modelVariant=${filters.modelVariant}`
        } else {
            queryParams = queryParams + `&modelVariant=`
        }
        if (filters.minPrice !== undefined) {
            queryParams = queryParams + `&minPrice=${filters.minPrice}`
        } else {
            queryParams = queryParams + `&minPrice=`
        }
        if (filters.maxPrice !== undefined) {
            queryParams = queryParams + `&maxPrice=${filters.maxPrice}`
        } else {
            queryParams = queryParams + `&maxPrice=`
        }
        if (filters.minYear !== undefined) {
            queryParams = queryParams + `&minYear=${filters.minYear}`
        } else {
            queryParams = queryParams + `&minYear=`
        }
        if (filters.maxYear !== undefined) {
            queryParams = queryParams + `&maxYear=${filters.maxYear}`
        } else {
            queryParams = queryParams + `&maxYear=`
        }
        if (filters.keyword !== undefined) {
            queryParams = queryParams + `&keyword=${filters.keyword}`
        } else {
            queryParams = queryParams + `&keyword=`
        }

        history.push({
            pathname: '/results',
            search: queryParams,
            state: {
                filters: filters,
                pagination: props.setPages((prevState) => ({
                    ...prevState,
                    currentPage: 1
                }))
            }
        })
    }

    const onModelChange = ({target: {value}}) => {
        setFilters({
            ...filters,
            model: value,
            modelVariant: ''
        });
    }

    const onModelVariantChange = ({target: {value}}) => {
        setFilters({
            ...filters,
            modelVariant: value
        });
    }

    const onMinPriceChange = ({target: {value}}) => {
        setFilters({
            ...filters,
            minPrice: filters.noPOA && value === ""? "1":value,
            noPOA: value !== ""
        });
    }

    const onMaxPriceChange = ({target: {value}}) => {
        setFilters({
            ...filters,
            maxPrice: value
        });
    }

    const onMinYearChange = ({target: {value}}) => {
        setFilters({
            ...filters,
            minYear: value
        });
    }

    const onMaxYearChange = ({target: {value}}) => {
        setFilters({
            ...filters,
            maxYear: value
        });
    }

    const onRemoveNoPrice = () => {
        setFilters({
            ...filters,
            noPOA: !filters.noPOA
        });
    }

    let maxOffset = 70;
    let thisYear = (new Date()).getFullYear();
    let allYears = [];
    for (let x = 0; x <= maxOffset; x++) {
        allYears.push(thisYear - x)
    }

    const yearList = allYears.map((x) => {
        return (<option key={x}>{x}</option>)
    });
    const yearListReverse = yearList.slice().reverse()

    const isSmall = window.innerWidth <= 975;

    return (
        <>
            <Container>
                <Styled>
                    <Accordion defaultActiveKey={isSmall? "1":"0"}>
                        <Card className={'titleCard'}>
                            <Accordion.Toggle as={Card.Header} eventKey="0" className={'accordionTitle'}>
                                <h4 className={'filtersTitle'}>Filters</h4>
                                <p className={'filtersSubText'}>Total results: {props.pages.totalResults}</p>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0" >
                                <Card.Body className={"titleBody"}>

                                    <Form className={'filterForm'}>
                                        <Accordion>

                                            <Card className={'filterCard'}>
                                                <Accordion.Toggle as={Card.Header} eventKey="0">
                                                    Model: {filters.model !== '' ? filters.model : 'ALL'}
                                                </Accordion.Toggle>
                                                <Accordion.Collapse eventKey="0">
                                                    <Card.Body>
                                                        <Form.Group key={filters.model}>
                                                            <Model model={filters.model} modelVariant={filters.modelVariant}
                                                                   Filters={filters}
                                                                   HandleModelChange={onModelChange}/>
                                                        </Form.Group>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>

                                            <Card className={'filterCard'}>
                                                <Accordion.Toggle as={Card.Header} eventKey="1">
                                                    Model
                                                    Variant: {filters.modelVariant !== '' ? filters.modelVariant : 'any'}
                                                </Accordion.Toggle>
                                                <Accordion.Collapse eventKey="1">
                                                    <Card.Body>
                                                        <Form.Group key={filters.modelVariant}>
                                                            <ModelVariant model={filters.model} modelVariant={filters.modelVariant}
                                                                          Filters={filters}
                                                                          HandleModelVariantChange={onModelVariantChange}/>
                                                        </Form.Group>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>

                                            <Card className={'filterCard'}>
                                                <Accordion.Toggle as={Card.Header} eventKey="2">
                                                    Minimum Price: {filters.minPrice !== '' ? filters.minPrice : 'any'}
                                                </Accordion.Toggle>
                                                <Accordion.Collapse eventKey="2">
                                                    <Card.Body>
                                                        <Form.Group key={filters.minPrice}>
                                                            <Form.Control as={"select"} className="form-control"
                                                                          defaultValue={filters.minPrice}
                                                                          onChange={onMinPriceChange}>
                                                                <option value="">Min price</option>
                                                                <option value="1000">£1,000</option>
                                                                <option value="2500">£2,500</option>
                                                                <option value="5000">£5,000</option>
                                                                <option value="10000">£10,000</option>
                                                                <option value="15000">£15,000</option>
                                                                <option value="20000">£20,000</option>
                                                                <option value="25000">£25,000</option>
                                                                <option value="30000">£30,000</option>
                                                                <option value="35000">£35,000</option>
                                                                <option value="40000">£40,000</option>
                                                                <option value="45000">£45,000</option>
                                                                <option value="50000">£50,000</option>
                                                                <option value="55000">£55,000</option>
                                                                <option value="60000">£60,000</option>
                                                                <option value="65000">£65,000</option>
                                                                <option value="70000">£70,000</option>
                                                                <option value="80000">£80,000</option>
                                                                <option value="90000">£90,000</option>
                                                                <option value="100000">£100,000</option>
                                                                <option value="120000">£120,000</option>
                                                                <option value="140000">£140,000</option>
                                                                <option value="160000">£160,000</option>
                                                                <option value="180000">£180,000</option>
                                                                <option value="200000">£200,000</option>
                                                            </Form.Control>
                                                        </Form.Group>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>

                                            <Card className={'filterCard'}>
                                                <Accordion.Toggle as={Card.Header} eventKey="3">
                                                    Maximum Price: {filters.maxPrice !== '' ? filters.maxPrice : 'any'}
                                                </Accordion.Toggle>
                                                <Accordion.Collapse eventKey="3">
                                                    <Card.Body>
                                                        <Form.Group key={filters.maxPrice}>
                                                            <Form.Control as={"select"} className="form-control"
                                                                          defaultValue={filters.maxPrice}
                                                                          onChange={onMaxPriceChange}>
                                                                <option value="">Max price</option>
                                                                <option value="1000">£1,000</option>
                                                                <option value="2500">£2,500</option>
                                                                <option value="5000">£5,000</option>
                                                                <option value="10000">£10,000</option>
                                                                <option value="15000">£15,000</option>
                                                                <option value="20000">£20,000</option>
                                                                <option value="25000">£25,000</option>
                                                                <option value="30000">£30,000</option>
                                                                <option value="35000">£35,000</option>
                                                                <option value="40000">£40,000</option>
                                                                <option value="45000">£45,000</option>
                                                                <option value="50000">£50,000</option>
                                                                <option value="55000">£55,000</option>
                                                                <option value="60000">£60,000</option>
                                                                <option value="65000">£65,000</option>
                                                                <option value="70000">£70,000</option>
                                                                <option value="80000">£80,000</option>
                                                                <option value="90000">£90,000</option>
                                                                <option value="100000">£100,000</option>
                                                                <option value="120000">£120,000</option>
                                                                <option value="140000">£140,000</option>
                                                                <option value="160000">£160,000</option>
                                                                <option value="180000">£180,000</option>
                                                                <option value="200000">£200,000</option>
                                                            </Form.Control>
                                                        </Form.Group>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>

                                            <Card className={'filterCard'}>
                                                <Accordion.Toggle as={Card.Header} eventKey="4">
                                                    Minimum Year: {filters.minYear !== '' ? filters.minYear : 'any'}
                                                </Accordion.Toggle>
                                                <Accordion.Collapse eventKey="4">
                                                    <Card.Body>
                                                        <Form.Group key={filters.minYear}>
                                                            <Form.Control as={"select"} className="form-control"
                                                                          defaultValue={filters.minYear}
                                                                          onChange={onMinYearChange}>
                                                                <option value="">Min year</option>
                                                                {yearListReverse}
                                                            </Form.Control>
                                                        </Form.Group>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>

                                            <Card className={'filterCard'}>
                                                <Accordion.Toggle as={Card.Header} eventKey="5">
                                                    Maximum Year: {filters.maxYear !== '' ? filters.maxYear : 'any'}
                                                </Accordion.Toggle>
                                                <Accordion.Collapse eventKey="5">
                                                    <Card.Body>
                                                        <Form.Group key={filters.maxYear}>
                                                            <Form.Control as={"select"} className="form-control"
                                                                          defaultValue={filters.maxYear}
                                                                          onChange={onMaxYearChange}>
                                                                <option value="">Max year</option>
                                                                {yearList}
                                                            </Form.Control>
                                                        </Form.Group>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>

                                            <Form.Group className={'filterCard'}>
                                                <Form.Check value={'1'} type="checkbox"
                                                            checked={filters.noPOA}
                                                            onChange={onRemoveNoPrice.bind(this)}
                                                            label="Remove listings without price (POAs)"/>
                                            </Form.Group>

                                        </Accordion>
                                        <Form.Group>
                                            <Button
                                                className={'searchButton'}
                                                variant={'success'}
                                                onClick={handleSubmit}
                                            >
                                                Search
                                            </Button>
                                        </Form.Group>
                                    </Form>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                </Styled>
            </Container>

        </>
    );
}

export default SideBarFilters;

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
}