import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import axios from "axios";
import {Breadcrumb, Col, Container, Form, Pagination, Row} from "react-bootstrap";
import BigCard from "./BigCard";
import ShowNotificationsWidget from "../SearchNotifications/ShowNotificationsWidget";
import Loader from "react-loader-spinner";
import styled from "styled-components";
import SideBarFilters from "./SideBarFilters";

const Styled = styled.div`

    .container {
        max-width: 1300px;
    }
    

    .mainBody {

    }

    .breadcrumb {

    }
    
    .pageButton {
        padding-right: 5px;
        padding-left: 5px;
        margin-bottom: auto;
        margin-top: auto;
        color: grey;
        font-size: small;
    }
    
    .filterBar {
        background: white;
    }
    
    @media (max-width: 1200px) {
        .filterBarCol {
            display: initial;
        }
    }
    
    .selectionSort {
        min-width: 165px;
    }
    
    .selectionPerPage {
        min-width: 150px;
    }
`

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function Results(props) {

    const [filters, setFilters] = useState({
        model: '',
        modelVariant: '',
        minPrice: '',
        maxPrice: '',
        minYear: '',
        maxYear: '',
        keyword: '',
    });

    const [pagination, setPagination] = useState({
        sortType: 'dateASC',
        currentPage: 1,
        totalPages: 1,
        totalResults: 0,
        perPage: 25,
    });

    const [loading, setLoading] = useState(true);

    let query = useQuery()

    const [results, setResults] = useState([])

    let history = useHistory();
    let modelFilter = lookup.filter((ele, ind) => ind === lookup.findIndex(elem => elem["Model_DB"] === ele["Model_DB"]))
    let modelVariantFilter = lookup.filter(d => d["Model_DB"] === query.get("model")).filter( (ele, ind) => ind === lookup.filter(d => d["Model_DB"] === query.get("model")).findIndex( elem => elem["Model Variant"] === ele["Model Variant"]))


    useEffect(() => {
            if (query.get("model") !== "" && modelFilter.find(element => element.Model_DB === query.get("model")) === undefined) {
                history.push({
                    pathname: '/invalid-results',
                    search: '?reason=Model'
                })
            } else if (query.get("modelVariant") !== "" && modelVariantFilter.find(element => element["Model Variant"] === query.get("modelVariant")) === undefined) {
                history.push({
                    pathname: '/invalid-results',
                    search: '?reason=Model Variant'
                })
            }
            setResults([])
            setFilters({
                model: query.get("model"),
                modelVariant: query.get("modelVariant"),
                minPrice: query.get("minPrice"),
                maxPrice: query.get("maxPrice"),
                minYear: query.get("minYear"),
                maxYear: query.get("maxYear"),
                keyword: query.get("keyword"),
            })
            setLoading(true)
            let apiCall = 'https://5xxxkq4zoa.execute-api.eu-west-2.amazonaws.com/dev/search/all/v2?sort=' + pagination.sortType + '&perPage=' + pagination.perPage + '&page=' + pagination.currentPage
            if (query.get("model") !== undefined) {
                apiCall = apiCall + `&model=${query.get("model")}`
            }
            if (query.get("modelVariant") !== undefined) {
                apiCall = apiCall + `&modelVariant=${query.get("modelVariant")}`
            }
            if (query.get("minPrice") !== undefined) {
                apiCall = apiCall + `&minPrice=${query.get("minPrice")}`
            }
            if (query.get("maxPrice") !== undefined) {
                apiCall = apiCall + `&maxPrice=${query.get("maxPrice")}`
            }
            if (query.get("minYear") !== undefined) {
                apiCall = apiCall + `&minYear=${query.get("minYear")}`
            }
            if (query.get("maxYear") !== undefined) {
                apiCall = apiCall + `&maxYear=${query.get("maxYear")}`
            }
            if (query.get("keyword") !== undefined) {
                apiCall = apiCall + `&keyword=${query.get("keyword")}`
            }
            axios.get(apiCall)
                .then(response => {
                        setResults(response.data)
                        if (response.data) {
                            setPagination((prevState) => ({
                                ...prevState,
                                totalResults: response.data[response.data.length - 1].totalResults,
                                totalPages: response.data[response.data.length - 1].totalPages,
                            }));
                        }
                    }
                )
                .catch(error => {
                    console.log(error)
                })


        }, [query.get("model"), query.get("modelVariant"), query.get("minPrice"), query.get("maxPrice"), query.get("minYear"), query.get("maxYear"), query.get("keyword"), pagination.currentPage, pagination.sortType, pagination.perPage]
    )

    let currentPage = pagination.currentPage
    let totalPages = pagination.totalPages
    let totalResults = pagination.totalResults

    const HandleSortChange = (event) => {
        event.preventDefault();
        setLoading(true)
        setPagination({
            ...pagination,
            sortType: event.target.value
        });
        currentPage = 0;
    }

    const HandlePerPageChange = (event) => {
        event.preventDefault();
        setLoading(true)
        setPagination({
            ...pagination,
            perPage: event.target.value
        });
        currentPage = 0;

    }

    const NextPage = (event) => {
        event.preventDefault();
        setLoading(true)
        setPagination((prevState) => ({
            ...prevState,
            currentPage: pagination.currentPage + 1
        }));
        window.scrollTo(0,0)
    }

    const PrevPage = (event) => {
        event.preventDefault();
        setLoading(true)
        setPagination((prevState) => ({
            ...prevState,
            currentPage: pagination.currentPage - 1
        }));
        window.scrollTo(0,0)
    }

    const HandleResultsLoaded = (event) => {
        event.preventDefault();
        setLoading(false);
    }


        return (
            <>
                <Styled>
                    <Container className={'mainBody'}>
                        <div style={{height: 100}}/>

                        <Breadcrumb>
                            <Breadcrumb.Item href={"/"}>Search</Breadcrumb.Item>
                            <Breadcrumb.Item active>Results</Breadcrumb.Item>
                        </Breadcrumb>
                        <Row>
                            <Col className={'filterBarCol'}>
                                <div className={'filterBar'}>
                                    <SideBarFilters info={filters} pages={pagination} setPages={setPagination}
                                                    setResults={setResults}/>
                                </div>
                                <ShowNotificationsWidget info={filters}/>
                            </Col>
                            <Col lg={9}>
                                <Row>
                                    <Col className={"col-sm"}>
                                        <Pagination>
                                            <Pagination.Prev className={'pageButton'} disabled={currentPage === 1}
                                                             value={currentPage - 1} onClick={PrevPage}/>
                                            <Pagination.Next className={'pageButton'}
                                                             disabled={totalResults === 0 || currentPage === totalPages}
                                                             value={currentPage + 1} onClick={NextPage}/>
                                            <p className={'pageButton'}>Page {currentPage} of {totalPages}</p>
                                        </Pagination>
                                    </Col>
                                    <Col className={"col-auto"}>
                                        <Row>
                                            <Col>
                                                <Form>
                                                    <Form.Control as={"select"} onChange={HandleSortChange}
                                                                  className={'sort selectionSort'}>
                                                        <option value={"dateASC"}>Newest to Oldest</option>
                                                        <option value={"dateDESC"}>Oldest to Newest</option>
                                                        <option value={"priceASC"}>Price Ascending</option>
                                                        <option value={"priceDESC"}>Price Descending</option>
                                                    </Form.Control>
                                                </Form>
                                            </Col>
                                            <Col>
                                                <Form>
                                                    <Form.Control as={"select"} onChange={HandlePerPageChange}
                                                                  className={'sort selectionPerPage'}>
                                                        <option value={25}>25</option>
                                                        <option value={50}>50</option>
                                                        <option value={100}>100</option>
                                                    </Form.Control>
                                                </Form>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                                <div onLoad={HandleResultsLoaded}>
                                    {
                                        results && results.length ? (totalResults === 0 ?
                                                <h1>No Results</h1>
                                                :
                                                results.slice(0, results.length - 1).map(result => {
                                                    return (
                                                        <BigCard className={'resultCard'} key={result.id}
                                                                 info={result}/>
                                                    )
                                                })
                                            ) :
                                            <div style={{display: loading ? "block" : "none"}}>
                                                <Loader
                                                    type="Bars"
                                                    color="#00BFFF"
                                                    height={100}
                                                    width={100}
                                                    timeout={3000} //3 secs

                                                />
                                            </div>
                                    }
                                </div>

                                <Pagination style={{display: loading ? "none" : "flex"}}>
                                    <Pagination.Prev className={'pageButton'} disabled={currentPage === 1}
                                                     value={currentPage - 1} onClick={PrevPage}/>
                                    <Pagination.Next className={'pageButton'}
                                                     disabled={totalResults === 0 || currentPage === totalPages}
                                                     value={currentPage + 1} onClick={NextPage}/>
                                    <p className={'pageButton'}>Page {currentPage} of {totalPages}</p>
                                </Pagination>
                            </Col>
                        </Row>
                    </Container>
                </Styled>
            </>
        );
    }

let lookup = require('./models.json');