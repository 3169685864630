import React from "react";
import {useAuth0} from "@auth0/auth0-react";
import LoginButton from "./LoginButton";
import LogoutButton from "./LogoutButton";

const AccountManager = () => {
    const {user, isAuthenticated, loginWithRedirect, isLoading} = useAuth0();

    if (isLoading) {
        return (
            <a>Loading</a>
        );
    }

    if (isAuthenticated) {
        return (
            <LogoutButton/>
        );
    }
    return <LoginButton/>

};

export default AccountManager;