import React from "react";
import logo from "../../Assets/Images/logo.png";
import styled from 'styled-components';
import {Card, Col, Container, Image, Row} from "react-bootstrap";


const Styled = styled.div`

    .mainDiv {
        height: 100%;
    }
    
    .footerParent {
        background: #004a75;
    }
    .footerText {
        color: white;
    }
    .footerCol {
        text-align:center;
        margin:0 auto;
        display:inline-block;
        vertical-align: middle;
        float: none;
    }
    
    .copyright {
        padding-top: 15px;
        color: white;
        text-align: center;
        font-size: small;
    }
    
    .footerLogo {
        padding-bottom: 10px;
        max-width: 500px;
        @media (max-width: 841px) {
            max-width: 250px;
        }
    }
`

export default function Footer() {
    return (
        <>
            <footer>
                <Styled>
                    <div className={'mainDiv'}>
                        <Card className={'footerParent'}>
                            <Card.Footer>
                                <Row>
                                    <Col className={'footerCol'}>

                                    </Col>
                                    <Col className={'footerCol'}>
                                        <Image src={logo} className={'footerLogo'}/>
                                        <Container>
                                            <h6 className={'footerText'}>
                                                "Porsche Heaven is an ongoing mission to provide a central, online hub
                                                for the Porsche enthusiast community. This is a part-time project so we
                                                appreciate any feedback and bug reports in addition to suggestions about
                                                how we can improve Porsche Heaven over time."
                                            </h6>
                                        </Container>
                                    </Col>
                                    <Col className={'footerCol'}>

                                    </Col>
                                </Row>
                                <p className={'copyright'}>
                                    © 2020 Porsche Heaven (porscheheaven.com) All Rights Reserved
                                </p>
                            </Card.Footer>
                        </Card>
                    </div>
                </Styled>
            </footer>

        </>
    )
}
