import React from "react";
import styled from "styled-components";
import {Container, Row} from "react-bootstrap";
import {useApi} from '../use-api';
import UnclassifiedCard from "./UnclassifiedCard";
import DashboardCard from "./DashboardCard";

const Styled = styled.div`

    .container {
        max-width: 1300px;
    }
    

    .mainBody {

    }
    
    .chart {
        height: 100%;
    }
    
    .card {
        background-color: #fff;
        background-clip: border-box;
        border: 1px solid #e3e6f0;
        border-radius: .35rem;
        box-shadow: 0 .15rem 1.75rem 0 rgba(58,59,69,.15);
        height: 100%;
    }
    
    .card-colour-line{
        border-left:0.25rem solid;
        height: 100%;
        padding: .5rem 0px;
    }
    
    .card-body {
        min-height: 1px;
        padding: 1.25rem;
    }
    
    .card-title {
        font-size: 0.7rem;
        font-weight: 700;
        white-space: nowrap;
    }
    
    .card-text {
        color: #5a5c69 !important;
        font-weight: 700; 
        margin-bottom: 0;
        font-size: 1.25rem;
        line-height: 1.2;
        white-space: nowrap;
    }
    
    .unclassified-title {
        font-size: 1.2rem;
        font-weight: 700;
        white-space: nowrap;
    }
    
    .unclassified-text {
        color: #5a5c69 !important;
        font-weight: 700; 
        margin-bottom: 0;
        font-size: 1.1rem;
        line-height: 1.2;
        white-space: nowrap;
    }
    
    .unclassified-small-title {
        font-size: 1rem;
        font-weight: 700;
        white-space: nowrap;
    }
    
    svg > g:last-child > g:last-child { pointer-events: none }
    div.google-visualization-tooltip { pointer-events: none }
`
export default function Dashboard() {
    const url = 'https://secure.api.porscheheaven.com/dashboard'
    const options = {
        audience: 'https://porscheheaven.eu.auth0.com/api/v2/'
    };
    const {loading, error, refresh, data} = useApi(url, options)

    if (loading) {
        return (
            <>
                <div style={{height: 100}}/>
                <div>Loading...</div>
            </>
        )
    }

    if (error) {
        return (
            <>
                <div style={{height: 100}}/>
                <div>{error.toString()}</div>
            </>
        )
    }

    return (
        <>
            <Styled>
                <Container className={'mainBody'}>
                    <div style={{height: 100}}/>
                    <Row>
                        <DashboardCard title={"Total Records"}
                                       info={data.total_ebay + data.total_car_and_classic + data.total_piston_heads}
                                       colour={"#004a75"}/>
                        <DashboardCard title={"Added records (Last 12hrs)"} info={data.total_daily} colour={"#1cc88a"}/>
                    </Row>
                    <Row>
                        <DashboardCard title={"Ebay"} info={data.total_ebay} colour={"#f6c23e"}/>
                        <DashboardCard title={"Car And Classic"} info={data.total_car_and_classic} colour={"#459F73"}/>
                        <DashboardCard title={"Piston Heads"} info={data.total_piston_heads} colour={"#007fad"}/>
                    </Row>
                    <Row>
                        <UnclassifiedCard info={data.unclassified_ebay} source={"_ebayID"} colour={"#f6c23e"}/>
                        <UnclassifiedCard info={data.unclassified_car_and_classic} source={"_carAndClassicID"}
                                          colour={"#459F73"}/>
                        <UnclassifiedCard info={data.unclassified_piston_heads} source={"_pistonheadsID"}
                                          colour={"#007fad"}/>
                        <UnclassifiedCard info={data.unclassified} source={""} colour={"#e74a3b"}/>
                    </Row>
                    <div style={{height: 10}}/>
                </Container>
            </Styled>
        </>
    )
}

