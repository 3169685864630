import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {useApi} from "../use-api";
import ModalWindow from "../ModalMessage/ModalWindow";
import {Button} from 'react-bootstrap';


const Styled = styled.div`
    .deleteButton {
        background-color: #bd2513;
        border-color: #bd2513;
    }

    .deleteButton:focus {
        box-shadow: 0 0 0 .2rem rgba(189,37,19,.5) !important;
    }

`

const PerformDelete = ({url, options, setDeletingID, setDeleteResult}) => {
    let {loading, error, data} = useApi(url, options)
    const [modal, setModal] = useState(false);
    const [response, setResponse] = useState({
        status: '',
        message: '',
        code: '',
        colour: ''
    })

    const triggrRefresh = (() => {
        setTimeout(() => {setDeletingID("refresh")}, 2000)
    })

    useEffect(() => {
        if (data !== null) {
            let reply = Object.values(data)
            setResponse({
                ...response,
                status: reply[0],
                message: reply[1],
                code: reply[2],
                colour: reply[0] === "Success"? '#28a745' : '#bd2513'
            })
            setModal(true)
        }
        if (error) {
            setResponse({
                ...response,
                status: "Failed",
                message: "Unknown Error",
                code: false,
                colour: '#bd2513'
            })
            setModal(true)
        }
    }, [loading])

    if (loading) {
        return (
            <>
                <Styled>
                    <Button className={"deleteButton"}>Deleting</Button>
                </Styled>
            </>
        )
    }
    if (error) {
        return (
            <>
                <Styled>
                    {modal? <ModalWindow setModal={setModal} colour={response.colour} message={response.message} setResult={setDeleteResult}/>:null}
                    <Button className={"deleteButton"}>Error performing delete</Button>
                </Styled>
            </>
        )
    }

    else {
        return (
            <>
                <Styled>
                    {modal? <ModalWindow setModal={setModal} colour={response.colour} message={response.message} setResult={setDeleteResult}/>:null}
                    <Button className={"deleteButton"}>Deleted</Button>

                    <p style={{display: 'none' }}>{setTimeout(() => {setDeletingID("refresh")}, 2000)}</p>

                </Styled>
            </>
        )
    }
}

export default PerformDelete;