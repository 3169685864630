import React, {useEffect, useState} from "react";
import {OverlayTrigger, Button, Tooltip} from "react-bootstrap";
import {useAuth0} from "@auth0/auth0-react";
import PerformAdd from "./PerformAdd";
import ModalWindow from "../ModalMessage/ModalWindow";

export default function SendNotification(props) {
    const {user, isAuthenticated} = useAuth0();
    const [allowed, setAllowed] = useState(true);
    const [addResult, setAddResult] = useState();
    const [disableButton, setDisableButton] = useState(false)
    const [modal, setModal] = useState(false);
    const [response, setResponse] = useState({
        code: '',
        message: '',
        status: '',
        colour: ''
    })
    const [buttonText, setButtonText] = useState("Add search to notifications")

    useEffect(() => {
        if (props.info["model"] === '' || !isAuthenticated) {
            setAllowed(false)
        } else {
            setAllowed(true)
        }
    })

    const addNotification = () => {
        let addURL = 'https://5xxxkq4zoa.execute-api.eu-west-2.amazonaws.com/dev/notifications/add?userId=' + user.sub.replace("|", "_");
        if (props.info["model"] !== '') {
            addURL = addURL + `&model=${props.info["model"]}`
        }
        if (props.info["modelVariant"] !== '') {
            addURL = addURL + `&modelVariant=${props.info["modelVariant"]}`
        }
        if (props.info["minPrice"] !== '') {
            addURL = addURL + `&minPrice=${props.info["minPrice"]}`
        }
        if (props.info["maxPrice"] !== '') {
            addURL = addURL + `&maxPrice=${props.info["maxPrice"]}`
        }
        if (props.info["minYear"] !== '') {
            addURL = addURL + `&minYear=${props.info["minYear"]}`
        }
        if (props.info["maxYear"] !== '') {
            addURL = addURL + `&maxYear=${props.info["maxYear"]}`
        }
        if (props.info["keyword"] !== '') {
            addURL = addURL + `&keyword=${props.info["keyword"]}`
        }

        let options = {audience: 'https://porscheheaven.eu.auth0.com/api/v2/'}
        setAddResult(<PerformAdd url={addURL} options={options} setDisableButton={setDisableButton}
                                 setAddResult={setAddResult} setButtonText={setButtonText}/>)
    }

    const noModel = () => {
        setResponse({
            ...response,
            status: "Failed",
            message: "Please select a Model",
            code: false,
            colour: '#bd2513'
        })
        setModal(true)
    }

    const notAuthenticated = () => {
        setResponse({
            ...response,
            status: "Failed",
            message: "Not Signed in",
            code: false,
            colour: '#bd2513'
        })
        setModal(true)
    }

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            select a model
        </Tooltip>
    );

    return (
        <>
            {modal? <ModalWindow setModal={setModal} colour={response.colour} message={response.message}/>:null}
            {addResult}
            {allowed?
                    <Button onClick={addNotification} disabled={disableButton}>{buttonText}</Button>
                    :
                    isAuthenticated?
                        <OverlayTrigger placement="auto" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
                            <Button onClick={noModel}>Add search to notifications</Button>
                        </OverlayTrigger>
                        :
                        <Button onClick={notAuthenticated}>Add search to notifications</Button>
            }
        </>
    );
}