import React from "react";
import styled from 'styled-components';
import {Col, Container, Image, Row} from "react-bootstrap";
import EBAY from "../../Assets/Images/EBAY-SMALL.png"
import PISTONHEADS from "../../Assets/Images/PISTONHEADS-SMALL.png"
import CARANDCLASSIC from "../../Assets/Images/CARANDCLASSIC-SMALL.png"


const Styled = styled.div`
    
    .mainDiv {
        position: relative;
        top: 0;
        background-color: f0f1f2;
    }
    
    .sourcesRow {
        margin: auto;
        padding-top: 15px;
        max-width: 1450px;
    }
    
    .sourceLogo {
        display: block;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0;
        max-width: 200px;
        @media (max-width: 841px) {
            max-width: 120px;
        }
        }
        
    .sourcesCol {
        text-align: center;
        margin:0 auto;
        display:inline-block;
        vertical-align: middle;
        float: none;
    }
    
    .text {
        font-size: large;
        color: #004a75;
    }
    
`
export default function SourcesSlider() {
    return (
        <>
            <Styled>
                <div className={'mainDiv'}>
                    <Row>
                        <Col className={'sourcesCol'}>
                            <Container>
                                <p className={'text'}>
                                    Porsche Heaven allows you to search all the major used-car sites in one go! If you cannot find what you are looking for, Porsche Heaven saves hassle by sending you a notification whenever a car matching your criteria gets listed. Give it a try!
                                </p>
                            </Container>
                        </Col>
                    </Row>
                    <Row xs={3} lg={3} className={'sourcesRow'}>
                        <Col className={'sourcesCol'}>
                            <Image className={'sourceLogo'} src={EBAY}/>
                        </Col>
                        <Col className={'sourcesCol'}>
                            <Image className={'sourceLogo'} src={PISTONHEADS}/>
                        </Col>
                        <Col className={'sourcesCol'}>
                            <Image className={'sourceLogo'} src={CARANDCLASSIC}/>
                        </Col>
                    </Row>
                </div>
            </Styled>
        </>
    )
}