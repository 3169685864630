import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import axios from "axios";
import {Button, Col, Container, Image, Row} from "react-bootstrap";
import styled from "styled-components";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import CarouselModal from "./CarouselModal";
import AUCTION from "../../Assets/Images/auction-icon.png";

const Styled = styled.div`
    .container {
        max-width: 1300px;
    }
    
    .mainBody {

    }

    .breadcrumb {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        flex-wrap: nowrap !important;
    }
    
    .breadcrumb-item.active {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    
    .subTitle {
        color: grey;
        font-size: 1.2rem;
    }
    
    .descriptionClosed {
        height: 30vh;
        overflow: hidden;
        color: transparent;
        width: 100%;
        border-color: transparent !important;
    }
    
    .descriptionOpen {
        overflow: hidden;
        width: 100%;
    }
    
    .arrow {
        border: solid black;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
        border-color: #AAAAAA !important;
    }
    
    .close {
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
        float: none;
        opacity: 1 !important;
    }

    .open {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
    }
    
    .readMore {
        text-align: center;
        width: 100%;
        height: 40px;
        background-color: transparent !important;
        border: transparent !important;
        box-shadow: 0 0 0 .1rem transparent !important;
        opacity: 1 !important;
    }
    
    .readMore:hover > .arrow {
        border-color: #000000 !important;
    }
    
    .mainColOne {
        min-width: 500px;
    }
    
    .mainColTwo {
        min-width: 500px;
        background: white;
    }
    
    @media(max-width: 500px) {
        .mainColOne {
            min-width: 350px;
        }
        .mainColTwo {
            min-width: 350px;
        }
    }
    
    .iconBar {
        margin-top: 10px;
        padding: 10px;
        text-align: center;
    }
    
    .iconBarMobile {
        display: none;
        margin-top: 10px;
        padding: 10px;
        text-align: center;
    }
    
    .link {
        width: 100%;
    }
    
    .linkMobile {
        display: none;  
        width: 100%;
    }
    
    @media(max-width: 1010px) {
        .iconBar {
            display: none;
        }
        .iconBarMobile {
            display: block
        }
        .link {
            display: none;
        }
        .linkMobile {
            display: block;
        }
    }
    
    .icon {
        height: 25px;
        width: 25px;
    }
    
    
    .info {
        color: #004a75;
        margin-top: 1rem;
        min-width: 100px;
    }
    
    .infoBig {
        color: #004a75;
        margin-top: 1rem;
    }
    
    .infoCol {
        min-width: 120px;
        background: white;
    }
    
    .descriptionIframe {
        height: 100%;
        width: 100%;
    }
    
    .auctionIcon {
        height: 25px;
        width: 25px;
    }
    
    .auctionChecked {
        font-size: 0.75em;
        color: #004a75;
    }
    
    .auctionText {
        color: #004a75;
    }
`
export default function Listings(props) {
    const ID = useParams()
    let description = useRef(null);

    const [result, setResult] = useState({
        "_id": null,
        "bodyType": null,
        "colour": null,
        "description": null,
        "lastChecked": null,
        "listingDate": null,
        "listingType": null,
        "mileage": null,
        "model": null,
        "modelVariant": null,
        "numberPlate": null,
        "pictures":null,
        "price": {
            "currency": null,
            "amount": null
        },
        "processed": null,
        "source": null,
        "source_id": null,
        "title": {
            "headline_title": null,
            "sub_title": null
        },
        "url": null,
        "year": null
    })

    const [readMore, setReadMore] = useState(true)
    const [descriptionHeight, setDescriptionHeight] = useState(false)
    const [iframeHeight, setIframeHeight] = useState({height: null})

    useEffect(() => {
        window.scrollTo(0, 0)
        let apiCall = 'https://5xxxkq4zoa.execute-api.eu-west-2.amazonaws.com/dev/search/listing/v2?id=' + ID.id
        axios.get(apiCall)
            .then(response => {
                setResult(response.data)
                let blob = new Blob([response.data.description], {type:'text/html'})
                document.getElementById("descriptionIframe").contentWindow.location.replace(URL.createObjectURL(blob));
            }
            )
            .catch(error => {
                console.log(error)
            })
        }, []
    )

    useLayoutEffect(() => {
        if(iframeHeight.height > windowHeight){
            setDescriptionHeight(true)
            setReadMore(false)
        }
    }, [iframeHeight])

    const handleReadMore = (event) => {
        event.preventDefault();
        setReadMore(!readMore);
    }

    let windowHeight = window.innerHeight * 0.34;

    if(result){
        let model = lookup.filter(d => d["Model_DB"] === result.model && d["Model Variant"] === result.modelVariant)["0"]
        return (
            <>
                <Styled>
                    <Container className={'mainBody'}>
                        <div style={{height: 100}}/>
                        <Breadcrumb>
                            <Breadcrumb.Item href={"/"}>Search</Breadcrumb.Item>
                            {props.location.state !== undefined ?
                                <Breadcrumb.Item
                                    href={"/results" + props.location.state.prevPath}>Results</Breadcrumb.Item>
                                :
                                <Breadcrumb.Item
                                    href={"/results?model=&modelVariant=&minPrice=&maxPrice=&minYear=&maxYear=&keyword="}>Results</Breadcrumb.Item>
                            }
                            <Breadcrumb.Item
                                active>{result.title.headline_title}</Breadcrumb.Item>
                        </Breadcrumb>
                        <Row>
                            <Col className={"mainColOne"}>
                                <CarouselModal pictures={result.pictures}/>
                                <div className={"iconBar"}>
                                    <Row>
                                        <Col className={"infoCol"}>
                                            <p className={"info"}>
                                                <h6>Year</h6> {result.year == null ? "Unknown" : result.year}
                                            </p>
                                        </Col>
                                        <Col className={"infoCol"}>
                                            <p className={"info"}>
                                                <h6>Mileage</h6> {result.mileage == null ? "Unknown" : result.mileage}
                                            </p>
                                        </Col>
                                        <Col className={"infoCol"}>
                                            <p className={"info"}>
                                                <h6>Colour</h6> {result.colour == null ? "Unknown" : result.colour}
                                            </p>
                                        </Col>
                                        <Col className={"infoCol"}>
                                            <p className={"info"}>
                                                <h6>Body</h6> {result.bodyType == null ? "Unknown" : result.bodyType}
                                            </p>
                                        </Col>
                                    </Row>
                                </div>
                                <Button className={'link'} variant="success" href={result.url} target={"_blank"}>Go to Listing</Button>
                            </Col>
                            <Col className={"mainColTwo"}>
                                <h2 className={"info"}>{result.title.headline_title}</h2>
                                {result.title.sub_title === null ? null :
                                    <h4 className={"subTitle"}>{result.title.sub_title}</h4>}

                                <div style={{height: 20}}/>
                                <hr/>
                                <div style={{height: 20}}/>
                                <h4>Overview</h4>
                                <div style={{height: 40}}/>

                                <Row xs={1} sm={3} md={3} lg={3} xl={3}>
                                    <Col className={"text-center"}>
                                        <h6>Price</h6>
                                        <h2 className={"infoBig"}>{result.price.amount === null? null : result.price.amount === 0 ? "POA" : "£" + result.price.amount}</h2>
                                        {
                                            result.listingType === 'auction'?
                                                <div>
                                                    <h4 className={"auctionText"}><Image className={'auctionIcon'} src={AUCTION} alt={"Auction"} fluid/>  AUCTION</h4>
                                                    <p className={"auctionChecked"}>Last updated: {result.lastChecked}</p>
                                                </div>
                                                :
                                                null
                                        }
                                    </Col>
                                    <Col className={"text-center"}>
                                        <h6>Model</h6>
                                        <h2 className={"infoBig"}>{result.model === null ? "" : model ? model["UI Model"] : null}</h2>
                                    </Col>
                                    <Col className={"text-center"}>
                                        <h6>Model Variant</h6>
                                        <h2 className={"infoBig"}>{result.modelVariant === null ? "" : result.modelVariant}</h2>
                                    </Col>
                                </Row>

                                <div style={{height: 40}}/>
                                <hr/>
                                <div style={{height: 20}}/>
                                <h4>Description</h4>
                                <div style={{height: 40}}/>

                                <div ref={(descriptionRef) => {description = descriptionRef;}}
                                     className={ descriptionHeight? (readMore ? "descriptionOpen" : "descriptionClosed") : "descriptionOpen"}
                                >
                                    <iframe frameBorder={"0"} scrolling={"no"} title={"descriptionTitle"} marginWidth={"0"}
                                            sandbox={"allow-modals allow-popups allow-top-navigation-by-user-activation allow-same-origin"}
                                            id={"descriptionIframe"}
                                            width={"100%"}
                                            onLoad={() => {
                                                document.getElementById("descriptionIframe").style.height = document.getElementById("descriptionIframe").contentWindow.document.body.scrollHeight + 'px';
                                                setIframeHeight({height: document.getElementById("descriptionIframe").contentWindow.document.body.scrollHeight})
                                                description.style.height = document.getElementById("descriptionIframe").contentWindow.document.body.scrollHeight;
                                            }}
                                    />
                                </div>
                                {descriptionHeight?
                                    <div className={"text-center"}>
                                        <button className={"readMore btn"} onClick={handleReadMore}>
                                            <i className={readMore ? "arrow close" : "arrow open"}/>
                                        </button>
                                    </div>
                                    :
                                    <p/>
                                }
                            </Col>
                        </Row>
                        <div className={"iconBarMobile"}>
                            <Row>
                                <Col className={"infoCol"}>
                                    <p className={"info"}>
                                        <h6>Year</h6> {result.year == null ? "Unknown" : result.year}
                                    </p>
                                </Col>
                                <Col className={"infoCol"}>
                                    <p className={"info"}>
                                        <h6>Mileage</h6> {result.mileage == null ? "Unknown" : result.mileage}
                                    </p>
                                </Col>
                                <Col className={"infoCol"}>
                                    <p className={"info"}>
                                        <h6>Colour</h6> {result.colour == null ? "Unknown" : result.colour}
                                    </p>
                                </Col>
                                <Col className={"infoCol"}>
                                    <p className={"info"}>
                                        <h6>Body</h6> {result.bodyType == null ? "Unknown" : result.bodyType}
                                    </p>
                                </Col>
                            </Row>
                        </div>
                        <div style={{height: 10}}/>
                        <Button className={'linkMobile'} variant="success" href={result.url} target={"_blank"}>Go to Listing</Button>
                        <div style={{height: 100}}/>
                    </Container>
                </Styled>
            </>
        )
    } else {
        return (
            <>
                <Styled>
                    <Container className={'mainBody'} style={{"height":"100vh"}}>
                        <div style={{height: 100}}/>
                        <Breadcrumb>
                            <Breadcrumb.Item href={"/"}>Search</Breadcrumb.Item>
                            {props.location.state !== undefined ?
                                <Breadcrumb.Item
                                    href={"/results" + props.location.state.prevPath}>Results</Breadcrumb.Item>
                                :
                                <Breadcrumb.Item
                                    href={"/results?model=&modelVariant=&minPrice=&maxPrice=&minYear=&maxYear=&keyword="}>Results</Breadcrumb.Item>
                            }
                            <Breadcrumb.Item active></Breadcrumb.Item>
                        </Breadcrumb>
                        <div>
                            <h1>Listing Not Found</h1>
                            <p>We couldn't find what you were looking for.</p>
                        </div>
                    </Container>
                </Styled>
            </>
        )
    }
}

let lookup = require('./../Search/models.json');