import React from 'react'
import styled from "styled-components";
import logo from "../../Assets/Images/logo.png";

const Brand = () => {
    return (
        <A href={"/"}>
            <Image src={logo} alt="Company Logo"/>
        </A>
    )
}

export default Brand

const Image = styled.img`
  height: 85%;
  margin: auto 0;
  @media (max-width: 1000px) {
    height: 55%;
  }
  @media (max-width: 375px) {
    height: 45%;
  }
`;

const A = styled.a`
  display: flex;
  justify-content: left;
  align-items: center;
  max-width: 230px;
`;