import React from "react";
import styled from "styled-components";
import {Container, Image, Jumbotron, Form, Row, Col} from "react-bootstrap";
import GarageBackdrop from'../../Assets/Images/GarageBackdrop-min.jpg'

const Styled = styled.div`
    .homeJumbo {
        background: url(${GarageBackdrop});
        background-size: cover;
        position: relative;
        height: 1000px;
        padding-top: 200px;
        padding-bottom: 10x;
        margin-bottom: -5px;
        
        @media (max-width: 841px) {
            padding-top: 170px;
        }
    }

    .mainDiv {
          width: 80%;
          padding-bottom: 55px;
          padding-top: 25px;
          background: white;
    }
    
    
 
`

export default function NotSignedIn() {


    return (
        <>
            <Styled>
                <Jumbotron className={'homeJumbo'}>
                    <Container>
                        <div className={"mainDiv"}>
                            <h3>My Garage</h3>

                            <hr/>

                            <h6>You need to be signed in to access your garage</h6>

                        </div>
                    </Container>
                </Jumbotron>

            </Styled>
        </>
    )
}