import React from 'react';
import {Form} from "react-bootstrap";

const Model = (props) => {
    let filtered = lookup.filter( (ele, ind) => ind === lookup.findIndex( elem => elem["Model_DB"] === ele["Model_DB"]))

    return (
        <Form.Control as={"select"} className="form-control"
                      defaultValue={props.model}
                      onChange={props.HandleModelChange}>
            <option value="">Select a Model</option>
            {filtered.map(o =>
                <option key={o["Model_DB"]} value={o["Model_DB"]}>{o["UI Model"]}</option>
            )}
        </Form.Control>
    )
}

export default Model;

let lookup = require('./models.json');