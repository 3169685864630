import React, {useState} from "react";
import {Container, Form, Button, Col} from "react-bootstrap";
import styled from "styled-components";
import axios from "axios";

const Styled = styled.div`
    .containerClass {
        background-color: white;
        width: 60%;
        margin: auto;
        margin-top: 150px;
        margin-bottom: 100px;
        padding: 20px;
        @media (max-width: 841px) {
            margin-top: 90px;
            margin-bottom: 10px;
            width: 100%;
        }
    }
    
    .submitButton {
        position: 'absolute',
        left: 0,
        top: 0,
        border: '5px solid green',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
        margin: auto;
    }
 
`

export default function ContactUs () {
    const [formDetails, setFormDetails] = useState([{
        email: '',
        firstname: '',
        lastname: '',
        reason: '',
        message: '',
    }]);

    const [sent, setSent] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(false)

    const HandleSend = (event) => {
        event.preventDefault()
        setSending(true)
        let url = 'https://5xxxkq4zoa.execute-api.eu-west-2.amazonaws.com/dev/contact'
        axios.post(url, {formDetails})
            .then(res => {
                console.log(res);
                console.log(res.data);
                setSending(false)
                setSent(true)
            })
            .catch(error => {
                setSending(false)
                setError(true)
            });
    }

    const HandleEmailChange = (event) => {
        event.preventDefault();
        setFormDetails({
            ...formDetails,
            email: event.target.value
        });
    }

    const HandleFirstNameChange = (event) => {
        event.preventDefault();
        setFormDetails({
            ...formDetails,
            firstName: event.target.value
        });
    }

    const HandleLastNameChange = (event) => {
        event.preventDefault();
        setFormDetails({
            ...formDetails,
            lastName: event.target.value
        });
    }

    const HandleReasonChange = (event) => {
        event.preventDefault();
        setFormDetails({
            ...formDetails,
            reason: event.target.value
        });
    }

    const HandleMessageChange = (event) => {
        event.preventDefault();
        setFormDetails({
            ...formDetails,
            message: event.target.value
        });
    }

    if (sent) {
        return (
            <>
                <Styled>
                    <div className={"containerClass"}>
                        <Container>
                            <h2>Contact Us</h2>
                            <p>Message Sent</p>
                        </Container>
                    </div>
                </Styled>
            </>
        )
    }
    if (sending) {
        return (
            <>
                <Styled>
                    <div className={"containerClass"}>
                        <Container>
                            <h2>Contact Us</h2>
                            <p>Sending Message</p>
                        </Container>
                    </div>
                </Styled>
            </>
        )
    }
    if (error) {
        return (
            <>
                <Styled>
                    <div className={"containerClass"}>
                        <Container>
                            <h2>Contact Us</h2>
                            <p>Error occurred when sending message</p>
                        </Container>
                    </div>
                </Styled>
            </>
        )
    }
    else {
        return (
            <>
                <Styled>
                    <div className={"containerClass"}>
                        <Container>
                            <h2>Contact Us</h2>
                            <Form onSubmit={HandleSend}>


                                <Form.Row>
                                    <Col>
                                        <Form.Group controlId="formBasicEmail" onChange={HandleEmailChange}>
                                            <Form.Label>Email address</Form.Label>
                                            <Form.Control type="email" placeholder="Enter email" required/>
                                            <Form.Text className="text-muted">
                                                We'll never share your email with anyone else.
                                            </Form.Text>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group controlId="formBasicType" onChange={HandleReasonChange}>
                                            <Form.Label className="my-1 mr-2" htmlFor="inlineFormCustomSelectPref">
                                                Reason
                                            </Form.Label>
                                            <Form.Control as="select" required>
                                                <option value="0">Choose...</option>
                                                <option value="Bug">Report a bug</option>
                                                <option value="Enquiry">General enquiry</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Form.Row>

                                <Form.Row>
                                    <Col>
                                        <Form.Group controlId="formBasicFirstName" onChange={HandleFirstNameChange}>
                                            <Form.Label>First Name</Form.Label>
                                            <Form.Control placeholder="First name" required/>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group controlId="formBasicLastName" onChange={HandleLastNameChange}>
                                            <Form.Label>Last Name</Form.Label>
                                            <Form.Control placeholder="Last name" required/>
                                        </Form.Group>
                                    </Col>
                                </Form.Row>

                                <Form.Group controlId="formBasicTextArea" onChange={HandleMessageChange}>
                                    <Form.Label>Your Message</Form.Label>
                                    <Form.Control as="textarea" rows="3" required/>
                                </Form.Group>

                                <div className={"submitButton"}>
                                    <Button variant={'success'} type={"submit"} className={"submitButton"}>
                                        Submit
                                    </Button>
                                </div>
                            </Form>
                        </Container>
                    </div>
                </Styled>
            </>
        );
    }

};
