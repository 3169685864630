import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Container, Image, Jumbotron, Form, Row, Col} from "react-bootstrap";
import {useAuth0} from "@auth0/auth0-react";
import GarageBackdrop from'../../Assets/Images/GarageBackdrop-min.jpg'
import AuthTest from "../Accounts/AuthTest";
import Notifications from "./Notifications";

const Styled = styled.div`
    .homeJumbo {
        background: url(${GarageBackdrop});
        background-size: cover;
        position: relative;
        padding-top: 200px;
        padding-bottom: 10x;
        margin-bottom: -5px;
        
        @media (max-width: 841px) {
            padding-top: 170px;
        }
    }
    
    .homeJumboMobile {
        background: url(${GarageBackdrop});
        background-size: cover;
        position: relative;
        padding-top: 80px;
        padding-bottom: 0px !important;
        margin-bottom: -5px;
        padding-right: 0px !important;
        padding-left: 0px !important;
    }
    
    .containerMobile {
        padding-right: 0px !important;
        padding-left: 0px !important;
    }
    
    .PorscheFinderLogo {
          display: block;
          margin-left: auto;
          margin-right: auto;
          width: 850px;
          
            @media (max-width: 841px) {
                width: 100%;
            }

    }

    .mainDiv {
          width: 80%;
          padding-bottom: 55px;
          padding-top: 25px;
          background: white;
    }
    
    .profileContainer {
        text-align: left
    }
    
    .profilePicture {
        min-height: 130px;
    }
    
    .userInfoForm {
        margin: auto;
        max-width: 80%;
    }
    
 
`

export default function Profile() {
    const {user, isAuthenticated} = useAuth0();
    const [screenWidth, setScreenWidth] = useState(0);

    useEffect(() => {
        setScreenWidth(window.screen.width);
    }, [])

    return (
        <>
            <Styled>
                <Jumbotron className={screenWidth > 850? 'homeJumbo' : 'homeJumboMobile'}>
                    <Container className={screenWidth > 850? null : 'containerMobile'}>
                        <div className={"mainDiv"}>
                            <h3>Welcome back to your garage!</h3>

                            <hr/>

                            <Image className={"profilePicture"} src={user.picture} roundedCircle/>

                            <hr/>

                            <Container>
                                <Form.Group as={Row} controlId="formPlaintextName">
                                    <Form.Label column sm="2">
                                        Name
                                    </Form.Label>
                                    <Col>
                                        <Form.Control type={"text"} className={"userInfoForm"} placeholder={user.name} readOnly/>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formPlaintextUsername">
                                    <Form.Label column sm="2">
                                        Username
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control type={"text"} className={"userInfoForm"} placeholder={user.username ? user.username : 'Not set'} readOnly/>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="2">
                                        Email
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control type={"text"} className={"userInfoForm"} placeholder={user.email} readOnly/>
                                    </Col>
                                </Form.Group>
                            </Container>
                            <hr/>
                            <Notifications/>
                        </div>
                    </Container>
                </Jumbotron>

            </Styled>
        </>
    )
}