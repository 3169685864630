import React from "react";
import styled from "styled-components";
import {animated, useSpring} from "react-spring";

export default function ModalWindow(props) {
    const ModalWrapper = styled.div`
         .closeButton {
            display: inline;
            color: #FFF !important;
            opacity: 0.6;
            font-size: 30px;
            float: left;
         }
        
         .closeButton:hover {
            opacity: 1;
         }
         
         .message {
            color: #FFF !important;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
         }
    `

    const Window = styled(animated.div)`
        position: fixed;
        top: 0;
        left: 0;
        z-index: 50;
        width: 100%;
        background-color: ${props.colour};
        
    `

    const handleClose = () => {
        props.setModal(false)
        props.setResult(undefined)
    }

    const barAnimation = useSpring({
        from: {transform: 'translate3d(0, -10rem, 0)'},
        transform: 'translate3d(0, 0, 0)',
    });

    return (
        <>
            <ModalWrapper>
                <Window style={barAnimation}>
                    <a className={"closeButton btn"} onClick={handleClose}>X</a>
                    <p className={"message"}>{props.message}</p>
                </Window>
            </ModalWrapper>
        </>
    );
}