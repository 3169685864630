import React from 'react';
import {Form} from "react-bootstrap";

const ModelVariant = (props) => {
    let options = lookup.filter(d => d["Model_DB"] === props.model).filter( (ele, ind) => ind === lookup.filter(d => d["Model_DB"] === props.model).findIndex( elem => elem["Model Variant"] === ele["Model Variant"]))
    let models = options.filter(i => i["Model Variant"] !== "")


    return (
        <Form.Control as={"select"} className="form-control"
                      defaultValue={props.modelVariant}
                      onChange={props.HandleModelVariantChange}>
            <option value="">Select a Model Variant</option>
            {models.map(o =>
                <option key={o["Model Variant"]} value={o["Model Variant"]}>{o["UI Model Variant"] === ""? "All" : o["UI Model Variant"]}</option>
            )}
        </Form.Control>
    )
}

export default ModelVariant;

let lookup = require('./models.json');