import React from "react";
import {Container} from "react-bootstrap";
import styled from "styled-components";
import Binoculars from "../../Assets/Images/Binoculars.png";
import {Link} from "react-router-dom";
import SendNotification from "./SendNotification";

const Styled = styled.div`

    .mainDiv {
        background-color: #004a75;
        margin-top: 0px;
        margin-bottom: 15px;
        padding: 15px;
    }

    .textContainer {
        text-align: center;
        color: white;
    }
    
    .binocularsIcon {
        width: 100px;
    }
    
    .innerText {
        padding-top: 10px;
    }
 
`

export default function ShowNotificationsWidget(props) {

    return (
        <>
            <Styled>
                <div className={'mainDiv'}>
                    <Container className={'textContainer'}>
                        <img src={Binoculars} className={'binocularsIcon'} alt="Binoculars"/>
                        <h2>Haven't found what you're after?</h2>
                        <p>Porsche Heaven can notify you whenever a new car in this search is listed</p>

                        <SendNotification info={props.info}/>

                        <Link to="/mygarage" style={{ color: 'white' }}>
                            <p className={'innerText'}>or manage your other notifications</p>
                        </Link>
                    </Container>
                </div>
            </Styled>
        </>
    );
}