import React, {useState} from 'react';
import {Carousel, Image} from "react-bootstrap";
import styled from "styled-components";

const CarouselModal = (props) => {
    const [showModal, setShowModal] = useState(false)

    const handleClose = () => {
        setShowModal(false)
        document.body.style.overflow = "auto";
        document.body.style.height = "100%";
    }

    return (
        <>
            <CarouselWrapper>
                <div className={showModal? "modal" : null}>
                    {showModal?<a className={"closeButton btn"} onClick={handleClose}>X</a>:null}
                    <Carousel className={showModal? "carousel-modal" : null} interval={null}>
                        {props.pictures ?
                            props.pictures.picture_1 ?
                                Object.values(props.pictures).map((picture) => {
                                    return (
                                        <Carousel.Item className={showModal? "carousel-item-modal" : null}>
                                            <span className={"helper"}/>
                                            <Image className={"carPicture"} src={picture}
                                                   onClick={() => {
                                                       setShowModal(true)
                                                       document.body.style.overflow = "hidden";
                                                       document.body.style.height = "100vh";
                                                   }} fluid/>
                                        </Carousel.Item>
                                    )
                                })
                                :
                                <div className={'loadingBox'}/>:
                            <div className={'loadingBox'}/>
                        }
                    </Carousel>
                </div>
            </CarouselWrapper>
        </>
    )
}

export default CarouselModal;

const CarouselWrapper = styled.div`
    .closeButton {
        position: fixed;
        z-index: 75;
        top: 0;
        right: 0;
        margin-top: 10px;
        margin-right: 10px;
        color: #FFF !important;
        opacity: 0.6;
        font-size: 30px;
    }

    .closeButton:hover {
        opacity: 1;
    }

   .modal {
        display: grid;
        position: fixed;
        z-index: 50;
        width: 100%;
        height: 100%;
        overflow: hidden;
        background-color: rgba(0,0,0,0.9);
    }

    .loadingBox {
        height: 100%;
        background-color: #e9ecef;
    }
    
    .carousel {
        text-align: center;
        height: 40vh;
    }
    
    .carousel-modal {
        max-height: 80vh;
        height:80vh;
        width: 100%;
        max-width: 100%;
        margin: auto;
    }
    
    .carousel-item {
        overflow: hidden;
        width: 100%;
        height: 100%;
        background-color: #e9ecef;
    }
    
    .carousel-item-modal {
        overflow: hidden;
        width: 100%;
        height: 100%;
        background-color: transparent;
    }
    
    .carousel-inner {
        height: 100%;
    }
    
    .helper {
        height: 100%;
        vertical-align: middle;
        display: inline-block;
    }
    
    .carPicture {
        max-width: 100%;
        width: 100%;
        object-fit: contain;
        height: 100%;
        cursor: pointer;
    }
`