import React from "react";
import Container from "react-bootstrap/Container";
import styled from "styled-components";
import {useHistory, useLocation} from "react-router-dom";
import {Button} from "react-bootstrap";

const Styled = styled.div`
    .mainBody {
        height: 100vh;
    }
    
    .notFoundText {
        text-align: center;
        position: absolute;
        top: 40%;
        left: 10%;
        right: 10%;
    }
`
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function NotFound() {
    let query = useQuery()
    let history = useHistory();

    const Home = () => {
        history.push({
            pathname: '/'
        })
    }

    return (
        <>
            <Styled>
                <Container className={'mainBody'}>
                    <div className={'notFoundText'}>
                        <h1>Query Invalid</h1>
                        <p>The query was invalid due to {query.get("reason")} not being a correct option.</p>
                        <Button variant={'success'} onClick={Home}>Go to Homepage</Button>
                    </div>
                </Container>
            </Styled>
        </>
    )
}