import React from "react";
import styled from "styled-components";
import {Container, Button} from "react-bootstrap";
import {useHistory} from "react-router-dom";

const Styled = styled.div`
    .mainBody {
        height: 100vh;
    }
    
    .notFoundText {
        text-align: center;
        position: absolute;
        top: 40%;
        left: 10%;
        right: 10%;
    }
`
export default function NotFound() {

    let history = useHistory();

    const Home = () => {
        history.push({
            pathname: '/'
        })
    }

    return (
        <>
            <Styled>
                <Container className={'mainBody'}>
                    <div className={'notFoundText'}>
                        <h1>Page Not Found</h1>
                        <p>Sorry, we couldn't find what you were looking for. But you can find more Porsches by searching on our Homepage.</p>
                        <Button variant={'success'} onClick={Home}>Go to Homepage</Button>
                    </div>
                </Container>
            </Styled>
        </>
    )
}