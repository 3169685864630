import React, {useEffect, useState} from "react";
import {useApi} from "../use-api";
import ModalWindow from "../ModalMessage/ModalWindow";

const PerformAdd = ({url, options, setDisableButton, setAddResult, setButtonText}) => {
    let {loading, error, refresh, data} = useApi(url, options)
    const [modal, setModal] = useState(false);
    const [response, setResponse] = useState({
        code: '',
        message: '',
        status: '',
        colour: ''
    })

    useEffect(() => {
        if (data !== null) {
            let reply = Object.values(data)
            setResponse({
                ...response,
                code: reply[0],
                message: reply[1],
                status: reply[2],
                colour: reply[0] === "Success"? '#28a745' : '#bd2513'
            })
            setModal(true)
            if (reply[2] === false) {
                setDisableButton(false)
                setButtonText("Add search to notifications")
            } else {
                setDisableButton(true)
                setButtonText("Added")
            }
        }
        if (error) {
            setResponse({
                ...response,
                status: "Failed",
                message: error.message,
                code: false,
                colour: '#bd2513'
            })
            setModal(true)
            setDisableButton(false)
            setButtonText("Add search to notifications")
        }
        if (loading) {
            setButtonText("Adding")
        }
    }, [loading])


    return(
        <>
            {modal? <ModalWindow setModal={setModal} colour={response.colour} message={response.message} setResult={setAddResult}/>:null}
        </>
    )
}

export default PerformAdd;