import React from "react";
import {Container} from "react-bootstrap";

export default function AboutUs() {
    return (
        <>
            <div style={{height: 150}}/>
            <Container style={{height: '59.8vh'}}>
                <h4>About Us</h4>
                <p>Porsche Heaven is an ongoing mission to provide a central, online hub for the Porsche enthusiast
                    community. We aim to provide a feature-rich enviroment for Porsche enthusiasts to find information,
                    explore and connect</p>
                <p>The Porsche Heaven Porsche Finder is an amazing, free tool that allows you to search the entire
                    internet for your dream Porsche in the click of a button. All the results are collected and
                    displayed in real time by our search engine.</p>
                <p>This is a part-time project so we appreciate any feedback and bug reports in addition to suggestions
                    about how we can improve Porsche Heaven over time.</p>
                <div style={{height: 300}}/>
            </Container>
        </>
    )

}

